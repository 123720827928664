import * as React from "react";
import { AppProperties } from "./app.properties";
import { GameConfiguration, GameTypes } from "@tgg/shared";
import getSymbolFromCurrency from "currency-symbol-map";
import RedBlackPage from "./components/redblack-page/redblack.page";

export default class App extends React.PureComponent<AppProperties, {
    userId?: number;
    screenHeight?: number;
    balance?: number;
    currencySign?: string;
    currency?: string;
    config?: GameConfiguration;
}> {
    public constructor(props: AppProperties) {
        super(props);
        this.state = { };
    }

    public componentDidMount(): void {
        const url = new URL(window.location.href);
        const marbleId = url.searchParams.get("playerToken");

        if (marbleId) {
            this.props.sessionService.getOrCreateSession(marbleId, GameTypes.RedBlack).then(
                (s) => {
                    this.props.financeService.onBalanceUpdated.sub(
                        (b) => this.setState(
                            {
                                balance: b.amount,
                                currency: b.currency,
                                currencySign: getSymbolFromCurrency(b.currency ?? "") ?? `${b.currency} `,
                            }
                        )
                    );
                    this.props.redBlackService.setToken(s.token);
                    this.props.financeService.setToken(s.token);
                    void this.props.financeService.connectWebSocket();
                    this.setState(
                        {
                            userId: s.userId,
                            config: s.config ?? undefined,
                        }
                    );
                },
                (e: string | Error) => alert(`Failed to authenticate: ${e.toString()}`)
            );
        }

        window.addEventListener("resize", this.onResize);
        this.onResize();
    }

    public componentWillUnmount(): void {
        this.props.financeService.onBalanceUpdated.clear();
        window.removeEventListener("resize", this.onResize);
    }

    public render(): React.ReactNode {
        return <RedBlackPage
            userId={this.state.userId}
            redblackService={this.props.redBlackService}
            balance={this.state.balance}
            currency={this.state.currency}
            currencySign={this.state.currencySign}
            config={this.state.config}
            style={{ ["--screen-height" as any]: this.state.screenHeight ? `${this.state.screenHeight}px` : "100vh" }}
        />;
    }

    private onResize = () => {
        this.setState(
            {
                screenHeight: window.innerHeight,
            }
        );
    };
}
