import { RedBlackHandDto } from "../services/redblack/dtos/redblack-hand.dto";
import { RedBlackHandPrediction } from "../services/redblack/enums/redblack-hand-prediction.enum";
import { RedBlackMatchDto } from "../services/redblack/dtos/redblack-match.dto";
import { ResultSet } from "../../../shared/src";
import React from "react";

export const RedBlackMinBetAmount = 2;
export const RedBlackMaxBetAmount = 3000;

export interface RedBlackContextValue {
    theme: "light" | "dark";
    match?: RedBlackMatchDto;
    lastHand?: RedBlackHandDto;
    currentHand: number; // zero based
    visibleHand: number; // zero based
    balance?: number;
    currency?: string;
    currencySign?: string;
    decimals?: number;
    isBusy: boolean;
    isDisabled: boolean;
    minDeposit: number;
    maxDeposit: number;
    endMatch?: () => void;
    endHand?: () => void;
    predictHand?: (prediction: RedBlackHandPrediction, amount: number) => void;
    getMatched?: () => Promise<ResultSet<RedBlackMatchDto>>;
}

export const RedBlackContext = React.createContext<RedBlackContextValue>(
    {
        theme: "light",
        currentHand: 0,
        visibleHand: 0,
        minDeposit: RedBlackMinBetAmount,
        maxDeposit: RedBlackMaxBetAmount,
        isBusy: false,
        isDisabled: true,
    }
);
